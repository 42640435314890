<template>
  <div class="display-images-panel">
    <b-tabs>
      <b-tab class="gallery-tab">
        <template #title>
          <p class="tab-title">Gallery</p>
        </template>
        <div class="gallery-content">
          <div class="gallery">
            <div v-for="image in images" :key="image.id" class="image-card mt-0 mr-4">
              <div :class="[selectedResource == image ? 'selected-image' : 'not-selected-image']" @click="chooseImage(image)">
                <img 
                  :src="image.image" 
                  :key="image.id" 
                  :alt="image.name"/>
              </div>
              <p class="filename-label">{{image.filename}}</p>
            </div>
          </div>
        </div>
        <div class="resource-section">
        <template v-if="selectedResource!=null">
          <p class="resource-title">{{ resourceTitle }}</p>
          <img :src="selectedResource.image" :key="selectedResource.id" :alt="selectedResource.name">
          <p class="resource-info">File URL: <a :href="resourceURL" target="_blank">Open link</a> </p>
          <p class="resource-info">Extension: {{ resourceExtension }} </p>
          <p class="resource-info">Properties: {{ resourceProperties }} </p>
        </template>
      </div>
      </b-tab>
      <b-tab>
        <template #title>
          <p class="tab-title">Upload</p>
        </template>
        <div class="upload-image">
          <FormulateInput
            label='Drop file to upload or click below'
            label-class='form-label'
            error-class='bigger-error'
            type='image'
            id='uploadedResource'
            name='uploadedResource'
            help='Select a PNG, JPG or JPEG image, file should be no larger than 10MB.'
            v-model='uploadedResource'
            validation="validateMaxFileSize|validateFileType"
            :validation-rules="{ validateMaxFileSize, validateFileType }"
            :validation-messages="{
              validateMaxFileSize: `Max file size is: ${maxFileSize / 1048576}MB`,
              validateFileType: 'Invalid file type'
            }"
            @file-upload-complete="uploadDone=true"
            @file-removed="uploadDone=false"/>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ImageViewCreationPanel',
  // props: {
  //   insightType: {
  //     type: String,
  //     requred: true
  //   }
  // },
  data() {
    return {
      uploadedResource: null,
      selectedResource: null,
      maxFileSize: 10485760,
      uploadDone: false,
      resourceTitle: '',
      resourceURL: '',
      resourceExtension: '',
      resourceProperties: 'Image'
    };
  },
  mounted() {
    this.selectImageForCurrentInsight();
  },
  computed: {
    images() {
      return this.studies.flatMap(study => study.study_images);
    },
    ...mapGetters('project', ['studies','currentInsight','currentInsights', 'editInsightMode'])
  },
  methods: {
    selectImageForCurrentInsight() {
      if (this.editInsightMode && this.currentInsight) {
        if (this.currentInsight.imageview_set.length > 0) {
          if (this.currentInsight.imageview_set[0].study_image?.image) {
            let selectedImage = this.images.find(img => img.id == this.currentInsight.imageview_set[0].study_image.id);
            this.chooseImage(selectedImage);
          }
        }
      }
    },
    async chooseImage(image) {
      this.selectedResource = image;
      let extensionIndex = image.filename.lastIndexOf('.');
      this.resourceTitle = image.filename.slice(0, extensionIndex);
      this.resourceURL = image.image;
      this.resourceExtension = image.filename.slice(extensionIndex).toLowerCase();

      this.$store.dispatch('project/setCurrentInsightResource', image);
    },
    validateFileType(context) {
      if (context?.value?.files) {
        for (const file of context.value.files) {
          let allowedTypes = ['png', 'jpg', 'jpeg'];
          let fileExtension = file.name.match(/\.(.+)$/);
          if (!allowedTypes.includes(fileExtension[1].toLowerCase())) {
            file.file['hasError'] = true;
            this.$forceUpdate();
            return false;
          }
        }
      }
      return true;
    },
    validateMaxFileSize(context) {
      if (context?.value?.files) {
        for (const file of context.value.files) {
          if (file.file.size > this.maxFileSize) {
            file.file['hasError'] = true;
            this.$forceUpdate();
            return false;
          }
        }
      }
      return true;
    }
  },
  watch: {
    uploadDone(newValue) {
      if (newValue==true) {
        this.$store.dispatch('project/setCurrentInsightResource', this.uploadedResource);
      } else {
        this.$store.dispatch('project/setCurrentInsightResource', null);
      }
    },
    currentInsight(newValue) {
      if (newValue) {
        this.selectImageForCurrentInsight();
      }
    }
  }
};
</script>

<style>


.filename-label {
  font-size: 0.75rem;
}

.display-images-panel .tab-content {
  border-left: 1px solid #E5E7EA !important;
  border-bottom: 1px solid #E5E7EA !important;
  border-right: 1px solid #E5E7EA !important;
}
.display-images-panel .tab-content, .display-images-panel .tab-pane {
  margin-top: 0rem !important;
  background-color: #F6F7F9;
  padding-left: 0.4rem;
}

.display-images-panel .nav-item > .nav-link {
  opacity: 0.7 !important;
  text-decoration: none;
  background-color: #F6F7F9 !important;
  border-color: #E5E7EA;
}
.display-images-panel .nav-item > .nav-link:hover {
  opacity: 1 !important;
  text-decoration: none;
  background-color: #F6F7F9 !important;
}
.display-images-panel .nav-item > .nav-link.active {
  border-bottom-color: #F6F7F9 !important;
  opacity: 1 !important;
  text-decoration: none;
  background-color: #F6F7F9 !important;
}

.bigger-error {
  font-size: larger;
  color: #960505;
}
</style>
<style scoped>

.selected-image {
  border: 2px rgb(77, 187, 255) solid !important;
}

/* 2px margin to allow the unselected images to align with the selected image, which has a 2px border */
.not-selected-image {
  margin: 2px;  
}

.display-images-panel {
  display: flex;
  flex-wrap: wrap;
}

.gallery-tab {
  display: flex;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  height: 15rem;
  overflow-x: auto;
  width: 49.5rem;
  background-color: #F6F7F9;
  margin-top: 0 !important;
  padding-top: 1rem !important;
}

/* scrollbar customization */
::-webkit-scrollbar {
  width: 0.3rem;
}
::-webkit-scrollbar-track {
  background: #E5E7EA;
  border-radius: 1rem;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.tab-title{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #596066;
  text-decoration: none;
}

.image-card {
  /* flex: 0 0 calc(100% / 6); */
  padding: 0.4rem;
  margin:0;
}

.image-card div {
  height: 6rem;
}

.image-card img {
  border-radius: 0.7rem;
  max-width: 7rem;
  max-height: 5rem;
  margin: 0;
  padding: 0.5rem;
}

.upload-image {
  height: 15.5rem;
  overflow-x: auto;
  width: 36.7rem;
  margin-top: 0 !important;
  padding: 2rem 13rem 0 13rem !important;
  text-align: center;
}

.resource-section {
  width: 11.65rem;
  background-color: #F6F7F9;
  padding: 1.5rem 0 1rem 1rem;
  margin-top: 0;
  margin-left: 0.5rem;
  border-left: 1px solid #E5E7EA !important;
  overflow-x: auto;
  max-height: 16.5rem;
}

.resource-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #596066;
  word-wrap: break-word;
}

.resource-info {
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #596066;
  margin-top: 0.7rem;
}

.resource-info a {
  text-decoration: none;
  color: #596066;
}

.resource-info a:hover {
  color: #E5E7EA;
}

.resource-section img{
  border-radius: 0.7rem;
  width: 7.3rem;
  height: 5rem;
  margin: 0;
  margin-top: 1rem;
}

@media screen and (max-width: 80rem) {
  .gallery-content, .gallery {
    width: 25.5rem !important;
    height: 11.5rem !important;
  }

  .resource-section {
    height: 11rem !important;
    width: 19.8rem !important;
  }

  .upload-image {
    width: 20.9rem !important;
    height: 11.5rem !important;
  }
}
</style>